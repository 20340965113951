<script setup lang="ts">
defineProps<{
  narrow?: boolean;
}>();

const { locale } = useI18n();
const formId = ref("");

function loadHubspotForm() {
  if (!(window as any).hbspt) {
    const existingScript = document.querySelector(
      "script[src=\"https://js.hsforms.net/forms/embed/22781716.js\"]",
    );
    if (existingScript) {
      existingScript.remove();
    }

    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/embed/22781716.js";
    script.defer = true;
    document.body.appendChild(script);
  }
}

onMounted(() => {
  formId.value
    = locale.value === "tr"
      ? "8eebc853-01df-4887-8cc5-f7bd57cae83e"
      : "c463b157-e594-45c0-b3a7-71a1c61996c1";

  loadHubspotForm();
});
</script>

<template>
  <div>
    <ClientOnly>
      <div
        class="hs-form-frame"
        data-region="na1"
        :data-form-id="formId"
        data-portal-id="22781716"
      />
    </ClientOnly>
  </div>
</template>

<style scoped>
.hs-form-frame .hsfc-Step__Content {
  padding: 0;
}
</style>
